import { IncomeAssetParts } from "src/backend/services/ocr/abstractions/property";
import { Rendered } from "src/classes/RenderedDoc";
import { RawCellContent } from "hyperformula";
import { GridState } from "src/classes/GridState";
import { TaxProperty } from "src/interfaces/TaxFormData/schedules/ScheduleE";
import { shortenPropertyName } from "src/classes/RenderedDocuments/utils";
import {
  ManyLoanCalculatorsData,
  ManyLoanCalculatorsRendered,
} from "src/classes/RenderedDocuments/LoanCalculatorRendered";
import { SupportedLenderId } from "src/interfaces/SpreadsConfig/SpreadsConfig";

export type YearWithPropertiesRowLabel =
  | "Gross Rents"
  | ""
  | "Net Income"
  | "Total Expenses"
  | "Interest"
  | "Depreciation"
  | "Amortization"
  | "NOI"
  | "Existing Debt"
  | "New Debt"
  | "Total Debt Service"
  | "Debt Service Coverage Ratio"
  | "Excess/Deficit"
  | "Interest Rate";

export class NOIAnalysisYearRollup extends Rendered<TaxProperty[], YearWithPropertiesRowLabel> {
  constructor(
    public year: string,
    public properties: TaxProperty[],
    public yearColumn: number,
    public formatNameForPersonalTaxReturn: (name: string) => string,
    public lenderId: SupportedLenderId = 1,
  ) {
    super(properties);
    this.underlying = properties;
  }

  get numberOfColumns(): number {
    return Object.keys(this.properties).length + 2;
  }

  getEndColumnLetter(): string {
    return this.colNumberToExcelCol(this.numberOfColumns);
  }

  getReferenceRowData(unused: any, rowOffset: number): RawCellContent[] {
    return this.properties.map((property) => {
      const propertyName = property?.propertyName || property?.propertyAddress || "";
      if (propertyName === "") {
        return "";
      }
      // Use same formatting method as defined in RenderedDocCreator to match tab names correctly
      const formattedPropertyName = this.formatNameForPersonalTaxReturn(propertyName as string);
      const reference = this.generateReference(formattedPropertyName, this.yearColumn, rowOffset);
      return `=${reference}`; // Construct reference formula
    });
  }

  generateTextPropertyArray(
    propertyName: keyof IncomeAssetParts,
    fallback?: keyof IncomeAssetParts,
  ): RawCellContent[] {
    return this.properties.map((property) => {
      return (
        (property[propertyName] as string) || (fallback && (property[fallback] as string)) || ""
      );
    });
  }

  get initialGridState(): GridState {
    if (this.numberOfColumns < 3) {
      return {};
    }
    const numCols = this.numberOfColumns;
    let index = 0;

    const mainPart = {
      // Row 1 - Source, e.g. "Rent Roll"
      source: {
        rowDataArray: ["Source", " ", ...this.getReferenceRowData("", 0)],
        rowDataType: "formula",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: index++,
      },
      // Row 2 - Entity
      entity: {
        rowDataArray: ["Entity", " ", ...this.getReferenceRowData("", 1)],
        rowDataType: "formula",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: index++,
      },
      // Row 3 - Property
      propertyName: {
        rowDataArray: [
          "Property",
          "Combined All Properties",
          ...this.generateTextPropertyArray("propertyName", "propertyAddress").map(
            shortenPropertyName,
          ),
        ],
        rowDataType: "text",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: index++,
      },
      // Row 4 - Year
      year: {
        rowDataArray: ["Year", "=C4", ...this.generateRepeatedValues(numCols - 1, `${this.year}`)],
        rowDataType: "text",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: index++,
      },
      // Row 5 - 1st blank row
      blankRow1: {
        rowDataArray: ["", " ", ...this.generateRepeatedValues(numCols - 1, "")],
        rowDataType: "text",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 6 - Gross Rents
      grossRents: {
        rowDataArray: [
          "Gross Rents",
          `=SUM(C6:${this.getEndColumnLetter()}6)`,
          ...this.getReferenceRowData("grossRents", 5),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 7 - Total Expenses
      totalExpenses: {
        rowDataArray: [
          "Total Expenses",
          `=SUM(C7:${this.getEndColumnLetter()}7)`,
          ...this.getReferenceRowData("totalExpenses", 6),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 8 - Net Income
      netIncome: {
        rowDataArray: [
          "Net Income",
          `=SUM(C8:${this.getEndColumnLetter()}8)`,
          ...this.getReferenceRowData("netIncome", 7),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 9 - Interest
      interest: {
        rowDataArray: [
          "Interest",
          `=SUM(C9:${this.getEndColumnLetter()}9)`,
          ...this.getReferenceRowData("interest", 8),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 10 - Depreciation
      depreciation: {
        rowDataArray: [
          "Depreciation",
          `=SUM(C10:${this.getEndColumnLetter()}10)`,
          ...this.getReferenceRowData("depreciation", 9),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 11 - Amortization
      amortization: {
        rowDataArray: [
          "Amortization",
          `=SUM(C11:${this.getEndColumnLetter()}11)`,
          ...this.getReferenceRowData("amortization", 10),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 12 - NOI (Net Income + Interest + Depreciation + Amortization)
      noi: {
        rowDataArray: [
          "Net Operating Income (NOI)",
          ...this.generateFormulaByColumnPattern(
            numCols,
            (colLabel: string) => `=SUM(${colLabel}8:${colLabel}11)`,
          ),
        ],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: index++,
      },
      // Row 13 - Existing Debt (entered by user)
      existingDebt: {
        rowDataArray: [
          "Existing Debt",
          `=SUM(C13:${this.getEndColumnLetter()}13)`,
          ...this.generateRepeatedValues(numCols - 1, 0),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 14 - New Debt  = [NOI)] * 1.25 - [Existing Debt]
      newDebt: {
        rowDataArray: [
          "New Debt",
          `=B12 / 1.25 - B13`,
          ...this.generateRepeatedValues(numCols - 1, " "),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 15 - Total Debt Service (Existing Debt + New Debt)
      totalDebtService: {
        rowDataArray: [
          "Total Debt Service",
          `=SUM(B13:B14)`,
          ...this.generateRepeatedValues(numCols - 1, " "),
        ],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: index++,
      },
      // Row 16 - Debt Service Coverage Ratio (NOI / Total Debt Service)
      dscr: {
        rowDataArray: [
          "Debt Service Coverage Ratio",
          `=B12/B15`,
          ...this.generateRepeatedValues(numCols - 1, " "),
        ],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: index++,
      },
      // Row 17 - Excess/Deficit (NOI - Total Debt Service)
      excessDeficit: {
        rowDataArray: [
          "Excess/Deficit",
          `=B12-B15`,
          ...this.generateRepeatedValues(numCols - 1, " "),
        ],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: index++,
      },
    } as GridState;

    const excessDeficitRefs: string[] = this.generateFormulaByColumnPattern(
      numCols,
      (colLabel: string) => `${colLabel}${index}`,
    );
    const newDebtRefs: string[] = this.generateFormulaByColumnPattern(
      numCols,
      (colLabel: string) => `${colLabel}14`,
    );

    const manyCalcData: ManyLoanCalculatorsData = {
      excessCashflows: excessDeficitRefs,
      lenderId: this.lenderId,
      newDebt: newDebtRefs,
    };
    const manyLoanCalcs = new ManyLoanCalculatorsRendered(manyCalcData, index + 1, true);

    return {
      ...mainPart,
      ...manyLoanCalcs.initialGridState,
    };
  }

  defaultRowLabels: YearWithPropertiesRowLabel[] = [
    "Gross Rents",
    "",
    "Net Income",
    "Interest",
    "Depreciation",
    "Amortization",
    "NOI",
    "Existing Debt",
    "New Debt",
    "Total Debt Service",
    "Debt Service Coverage Ratio",
    "Excess/Deficit",
  ];

  highlightedRowLabels: YearWithPropertiesRowLabel[] = [
    "NOI",
    "Total Debt Service",
    "Debt Service Coverage Ratio",
    "Excess/Deficit",
  ];

  percentageRowLabels: YearWithPropertiesRowLabel[] = ["Interest Rate"];
}
