import type { RawCellContent } from "hyperformula";
import { AutoRendered, autoRenderedTextRow } from "src/classes/AutoRendered";
import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import { Form1120sIncomeStatementGrouped } from "./Form1120sIncomeStatementGrouped";
import { Form1120sBalanceSheetGrouped } from "./Form1120sBalanceSheetGrouped";
import { Form1120sCashFlowGrouped } from "./Form1120sCashFlowGrouped";
import { buildForm1120sRatios, Form1120sRatiosRowHeadings } from "./Form1120sRatios";
import {
  AutoRenderedSheetBuilderWithConfidence,
  RawConfidenceContent,
} from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { RenderableWithConfidence } from "src/classes/RenderedDocuments/Workflows/TaxFormWithConfidence";
import { AutoRenderedSheetBuilder } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilder";
import { sanitizeTabName } from "../utils";

export class Form1120sRatiosGrouped extends AutoRendered<RenderableWithConfidence<Form1120S>[]> {
  private rendered: AutoRenderedSheetBuilder<Form1120S, typeof Form1120sRatiosRowHeadings>[] = [];
  private startingRow = 1;
  constructor(
    public taxData: RenderableWithConfidence<Form1120S>[],
    public balanceSheetTabName: string,
    public incomeStatementTabName: string,
    public cashFlowTabName: string,

    public balanceSheet: Form1120sBalanceSheetGrouped,
    public incomeStatement: Form1120sIncomeStatementGrouped,
    public cashFlow: Form1120sCashFlowGrouped,
  ) {
    super(taxData);

    const data = this.underlying.sort(
      (a, b) => parseInt(a.renderable.year) - parseInt(b.renderable.year),
    );

    data.forEach((form, i) => {
      const startingColumn = this.getColumnLetter(i);
      const singleRendered = buildForm1120sRatios(
        form,
        sanitizeTabName(this.balanceSheetTabName),
        sanitizeTabName(this.incomeStatementTabName),
        sanitizeTabName(this.cashFlowTabName),
        this.balanceSheet,
        this.incomeStatement,
        this.cashFlow,
        startingColumn,
        this.startingRow,
      );
      this.rendered.push(singleRendered);
    });
  }

  private getColumnLetter(offset: number): string {
    // +1 because we start with "A" for the year column
    // +1 because Excel is 1-indexed but our offsets are 0-indexed
    return this.colNumberToExcelCol(offset + 2);
  }

  asConfidence(): RawConfidenceContent[][] {
    const header = [0, ...Array(this.rendered.length).fill(0)];
    const body: RawConfidenceContent[][] = [];
    this.rendered.forEach((rendered, i) => {
      const singleRendered = rendered.asConfidence();
      singleRendered.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0]];
        }
        body[rowIndex].push(row[1]);
      });
    });

    return [header, ...body];
  }

  asColumns(): RawCellContent[][] {
    const data = this.underlying.sort(
      (a, b) => parseInt(a.renderable.year) - parseInt(b.renderable.year),
    );
    const header = autoRenderedTextRow(["Year", ...data.map((r) => r.renderable.year)]);
    const body: RawCellContent[][] = [];

    this.rendered.forEach((singleRendered, i) => {
      const formColumns = singleRendered.asColumns();
      formColumns.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0]];
        }
        body[rowIndex].push(row[1]);
      });
    });

    return [header, ...body];
  }

  get highlightedRowLabels() {
    return ["Year"];
  }

  get percentageRowLabels() {
    return [];
  }

  public indexOfHeader(header: keyof typeof Form1120sRatiosRowHeadings): number {
    const heading = Form1120sRatiosRowHeadings[header];
    return this.asColumns().findIndex((row) => row[0] === heading);
  }
}
