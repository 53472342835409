import { ScheduleC } from "src/interfaces/TaxFormData/schedules/ScheduleC";
import { AutoRendered } from "src/classes/AutoRendered";
import { RawCellContent } from "hyperformula";

export class ScheduleCBreakoutRendered extends AutoRendered<ScheduleC[]> {
  asColumns(): RawCellContent[][] {
    if (this.underlying.length === 0) {
      return [];
    }

    const headers = [
      [
        "Tax Return Cell",
        "31 Net Profit",
        "12 Depletion",
        "13 Depreciation",
        "16a Mortgage Interest",
        "16b Other Interest",
        "27a Amortization ",
        "Cash Flow ",
      ],
    ];

    const col = this.columnId;
    const netProfitColumn = this.colNumberToExcelCol(this.excelColToColIndex(col) + 1);
    const amortizationColumn = this.colNumberToExcelCol(this.excelColToColIndex(col) + 6);
    const cashFlowColumn = this.colNumberToExcelCol(this.excelColToColIndex(col) + 7);

    const body = this.underlying.map((schedule, index) => {
      const rowIndex = index + 2;
      return [
        schedule.businessName,
        schedule.netProfit ?? 0,
        schedule.depletion ?? 0,
        schedule.depreciation ?? 0,
        schedule.mortgageInterest ?? 0,
        schedule.otherInterest ?? 0,
        schedule.amortization ?? 0,
        `=SUM(${netProfitColumn}${rowIndex}:${amortizationColumn}${rowIndex})`,
      ];
    });

    const footers = [
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "Schedule C EBIDA",
        `=SUM(${cashFlowColumn}2:${cashFlowColumn}${body.length + 1})`,
      ],
    ];

    return [...headers, ...body, ...footers];
  }
  get highlightedRowLabels(): string[] {
    return [
      "Tax Return Cell",
      "31 Net Profit",
      "12 Depletion",
      "13 Depreciation",
      "16a Mortgage Interest",
      "16b Other Interest",
      "27a Amortization ",
      "Cash Flow ",
    ];
  }
  get percentageRowLabels(): string[] {
    return [];
  }

  constructor(public taxData: ScheduleC[]) {
    super(taxData);
  }
}
